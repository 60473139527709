


export const removeProperties = (obj, propertiesToRemove) => {
  const modifiedObject = { ...obj };
  propertiesToRemove.forEach(prop => delete modifiedObject[prop]);
  return modifiedObject;
};



